<template>
	<h1>
		{{ titlefy(entityType) }}<em class="bump">{{ prettyNr(total, $imperial) }}</em>
	</h1>

	<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />

	<div class="people">
		<div v-for="(day, i) in entitiesByDay" :key="i">
			<h3 class="day-count">
				<span>{{ day.date }}</span
				>{{ day.people.length }} Signup{{ day.people.length > 1 ? 's' : '' }}
			</h3>
			<template v-for="(person, j) in day.people" :key="j">
				<div class="person">
					<div class="index">{{ person.index }}</div>
					<div v-if="person.username" class="name">
						<router-link :to="{ name: 'User', params: { username: person.username } }">{{
							person.name || '/'
						}}</router-link>
					</div>
					<div v-else class="name">{{ person.name || '/' }}</div>
					<div class="email">
						<a :href="`mailto:${person.email}`">{{ person.email }}</a>
					</div>
					<div class="type">{{ type(person) }}</div>
					<div v-html="domain(person.email)" class="domain"></div>
					<div class="date">{{ person.created }}</div>
				</div>
			</template>
			<div class="sep"></div>
		</div>
	</div>

	<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import BasePagination from '@/components/BasePagination'

// Internal
import { prettyNr, titlefy } from '@/use/StringMagic'
import { injectMetaData } from '@/use/MetaData'

// External
import moment from 'moment'

export default {
	name: 'AdminMonitorNewPeople',
	components: { BasePagination },
	props: {
		entityType: {
			type: String,
			validator: function(value) {
				// The value must match one of these strings
				return ['wait-list', 'new-users'].indexOf(value) !== -1
			},
		},
		page: {
			type: Number,
			default: 1,
		},
	},
	setup(props) {
		injectMetaData({
			title: `Admin: Monitor - ${titlefy(props.entityType)}`,
		})

		const apiStore = useApiStore()
		const adminMonitorApi = apiStore.loadApi('adminMonitor')

		return { adminMonitorApi, prettyNr, titlefy }
	},
	data() {
		return {
			entitiesByDay: '',
			total: '',
			pageCount: '',
		}
	},
	watch: {
		page() {
			this.loadList()
		},
	},
	mounted() {
		this.loadList()
	},
	methods: {
		async loadList() {
			const { data } = await this.adminMonitorApi.getLatest(this.entityType, this.page)
			const { entities, total, pageCount } = data
			this.entitiesByDay = this.groupEntities(entities)
			this.total = total
			this.pageCount = pageCount

			// Forward URL to /1
			if (this.pageCount > 1 && !this.$route.params.page) {
				this.$router.push({
					name: this.$route.name,
					params: { page: 1 },
				})
			}
		},
		groupEntities(entities) {
			const entitiesByDay = []
			let day
			entities.forEach((person, i) => {
				const date = new Date(person.created)
				person.created = moment(person.created).format('MMM D, YYYY - HH:mm')
				if (i == 0 || date.getDay() != day) {
					// Start new group
					const day = moment(date).format('MMMM D')
					entitiesByDay.push({ date: day, people: [person] })
				} else {
					// Add to last group
					entitiesByDay[entitiesByDay.length - 1].people.push(person)
				}
				day = date.getDay()
			})
			return entitiesByDay
		},
		type(person) {
			return person.isArtist ? 'Artist' : person.isProfessional ? 'Prof.' : person.isLover ? '...' : '-'
		},
		domain(email) {
			const domain = email.split('@')[1]
			const domainStripped = domain.split('.')[0]
			// prettier-ignore
			const ignore = [
				'gmail',		'shapish',		'outlook',			'aol',
				'me',			'hotmail',		'googlemail',		'yahoo',
				'icloud',		'web',			't-online',			'ymail',
				'protonmail',	'telenet',		'qq',				'gmx',
				'skynet',		'frontier',		'comcast',			'walla',
				'mail',			'163',			'netscape',			'pandora',
				'mac',			'optonline',	'sky',				'foxmail',
				'senet',		'binternet',	'bluewin',			'live',
				'msn',			
			]
			return ignore.includes(domainStripped)
				? '-'
				: `<a target="_blank" href="http://${domain}">${domain}</a>`
		},
	},
}
</script>

<style lang="scss" scoped>
.people {
	margin-bottom: 0.4rem;
}
.person {
	height: 0.3rem;
	line-height: 0.3rem;
	display: flex;
}
.person .index {
	flex: 0 0 0.5rem;
}
.person .name {
	flex: 0 01.5rem;
}
.person .email {
	flex: 0 02rem;
}
.person .type {
	flex: 0 0 0.7rem;
}
.person .domain {
	flex: 0 02rem;
}
.person .date {
	flex: 1 02rem;
}
.person .name,
.person .email {
	margin-right: 0.1rem;

	// Truncate
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.day-count {
	margin-top: 0.4rem;
	display: block;
}
.day-count span {
	width: 2.1rem;
	display: inline-block;
}
.sep {
	width: 8.3rem;
	height: 0;
	margin: 0.4rem 0;
	border-bottom: dashed 0.01rem $black-10;
}
</style>
